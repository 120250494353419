<template>
  <div class="container-fluid">
    <div class="row h-100 justify-content-start">
      <div class="left col-4 login_content">
        <div class="form-container">
          <div class="login_form_header">
            <img src="../../assets/images/login-form-header.png" alt="" />
          </div>
          <form @submit.prevent="login" action="" class="">
            <h2>Login</h2>
            <div class="form-group mb-3">
              <label class="mb-2">Email Address</label>
              <input
                type="email"
                class="form-control"
                placeholder="Enter Your Email"
                v-model="user.email"
              />
            </div>
            <div class="form-group mb-3">
              <label class="mb-2">Password</label>
              <input
                type="password"
                class="form-control"
                placeholder="Enter Your Password"
                v-model="user.password"
              />
            </div>
            <div class="form-group mt-3">
              <button class="btn login-btn">Login</button>
            </div>
          </form>
          <div class="copy_rights">© 2023 all rights reserved</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container-fluid {
  height: 100vh;
  padding: 0;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  background-image: url('../../assets/images/login-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.login_content{
    margin-left: 5em;
}
.img-container {
  display: block;
  width: 40%;
}
.img-container img {
  width: 100%;
}
.login_form_header {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 0 2rem;
}
.form-container {
  width: 100%;
  /* background-color: #eee; */
  margin: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.form-container form {
  min-height: 40vh;
  width: 100%;
  padding: 3rem 2rem;
  /* color: #fff; */
  /* border: solid 1px #eee; */
  border-radius: 5px;
  /* background-color: #70707096; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.form-container .copy_rights {
  color: #747474;
  font-weight: 400;
  font-size: 14px;
}
.form-container form h2 {
  color: #3c3c3b;
  font-weight: 700;
  position: relative;
  margin-bottom: 1em;
  padding-bottom: 16px;
  width: fit-content;
}
.form-container form h2:after {
  content: "";
  position: absolute;
  height: 3px;
  width: 60%;
  background: #f47d1f;
  bottom: 0;
  left: 0;
  border-radius: 1px;
}
.form-group label {
  font-weight: 500;
  color: #3c3c3b;
}
.login-btn {
  width: 100%;
  background-color: #f47d1f;
  color: #fff;
  border-radius: 25px;
  font-weight: 500;
  padding: 0.5em;
  font-size: 18px;
}
.login-btn:hover {
  width: 100%;
  background-color: #f47d1f;
  color: #fff;
}
.login-btn:focus {
  width: 100%;
  background-color: #f47d1f;
  color: #fff;
}
.login-btn:active {
  width: 100%;
  background-color: #f47d1f;
  color: #fff;
}
.form-control {
  background-color: transparent;
  border-color: #707070;
  border-radius: 25px;
  padding: 0.5em 1em;
  font-size: 16px;
}
.form-control::placeholder {
  color: #bebebe;
  font-size: 16px;
}
</style>

<script>
import { createToaster } from "@meforma/vue-toaster";

export default {
  name: "login-page",
  data() {
    return {
      user: {
        email: null,
        password: null,
      },
      error: null,
    };
  },
  methods: {
    async login() {
      this.error = null;
      const toaster = createToaster();
      try {
        if (
          this.user.email !== null &&
          this.user.email !== "" &&
          this.user.password !== null &&
          this.user.password !== ""
        ) {
          await this.$store.dispatch("login", this.user);
          let data = JSON.parse(localStorage.getItem("user"));

          if (data?.role === "manager") {
            await this.$router.push({ name: "home-page" });
          }
          if (data?.role === "finance") {
            await this.$router.push({ name: "home-admin" });
          }
          if (data?.role === "sales_account") {
            await this.$router.push({ name: "sales-account-page" });
          }
          if (data?.role === "sales_manager_account") {
            await this.$router.push({ name: "sales-manager-account-page" });
          }
          if (data?.role === "market_impact_account") {
            await this.$router.push({ name: "market-impact-account-page" });
          }
        } else {
          toaster.warning("Please fill username and password inputs", {
            position: "top",
          });
        }
      } catch (e) {
        console.log(e);
        this.error = e;
      }
    },
  },
};
</script>