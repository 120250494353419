<!-- eslint-disable vue/require-v-for-key -->
<template>
  <div class="sales-manager-account">
    <div class="page-header">
      <h4>
        Welcome Back ,
        <div class="name">{{ salesManagerAccount }}</div>
      </h4>
    </div>

    <div class="page-content">
      <form class="form mb-3">
        <div class="controls-container d-flex">
          <div class="form-group me-2">
            <label for="" class="mb-2"> Clusters</label>
            <select class="form-select" id="inlineFormSelectPref" @change="getClusterSelected" v-model="selected">
              <option selected disabled value="">Choose cluster</option>
              <option v-for="cluster in clusters" :key="cluster.id" :value="cluster.id">
                {{ cluster.email }}
              </option>
            </select>
          </div>
          <div class="d-flex align-items-end">
            <button :class="{ disabled: isDisabledOne }" class="btn filter-btn" @click.prevent="getCouesData">
              Filter
            </button>
          </div>
          <div class="d-flex align-items-end flex-grow-1 justify-content-end">
            <button class="btn screen-btn" @click.prevent="moveToScreen">
              Screen
            </button>
          </div>
        </div>
      </form>

      <div v-if="goal !== null && isDataComing === true">
        <div class="targets">
          <div class="quantum content">
            <div class="title">
              <h5>Cluster Quantum</h5>
            </div>
            <div class="details">
              <h5>
                {{
                new Intl.NumberFormat("en-DE", {
                style: "currency",
                currency: "dhs",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                }).format(Math.round(goal)) ?? 0
                }}
              </h5>
            </div>
          </div>

          <div class="potentiality content">
            <div class="title">
              <h5>Potentiality</h5>
            </div>
            <div class="details">
              <h5>
                {{
                new Intl.NumberFormat("en-DE", {
                style: "currency",
                currency: "dhs",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                }).format(Math.round(potentiality)) ?? 0
                }}
              </h5>
            </div>
          </div>
          <div class="awarded content">
            <div class="title">
              <h5>Awarded</h5>
            </div>
            <div class="details">
              <h5>
                {{
                new Intl.NumberFormat("en-DE", {
                style: "currency",
                currency: "dhs",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                }).format(Math.round(totalOpportunityAwarded)) ?? 0
                }}
              </h5>
            </div>
          </div>
          <div class="conversion content">
            <div class="title">
              <h5>Conversion</h5>
            </div>
            <div class="details">
              <h5 v-if="awarded || potentiality">{{ Math.round((awarded / potentiality) * 100) ?? 0 }} % </h5>
            </div>
          </div>
          <div class="achievement content">
            <div class="title">
              <h5>Achievement</h5>
            </div>
            <div class="details">
              <h5>{{ Math.round(achievement * 100) ?? 0 }} %</h5>
            </div>
          </div>
          <!-- <div class="impact content">
            <div class="title">
              <h5>Market Imapct</h5>
            </div>
            <div class="details">
              <h5 v-if="po === 0">0%</h5>
              <h5 v-else>{{ Math.round((this.awarded / this.po) * 100) }}%</h5>
            </div>
          </div> -->
        </div>
        <div class="account mt-3" v-for="(company, idx) in companies" v-bind:key="idx">
          <div class="account-name">
            <h3>{{ idx }}</h3>
          </div>
          <div class="tables__container d-flex">
            <div class="table__parent left_table w-40">
              <table class="w-100">
                <thead>
                  <tr>
                    <th class="comp__proposals">
                      <h5>Proposals</h5>
                    </th>
                    <th class="comp__job-code">
                      <h5>Job Code</h5>
                    </th>
                    <th class="comp__amount">
                      <h5>Amount</h5>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, idx) in company" :key="idx">
                    <td class="comp__proposals">
                      <div>
                        {{ data.lead_title }}
                      </div>
                    </td>
                    <td class="comp__job-code">
                      <div class="name comp__job-code_val ">
                        {{ data.jpb_code ?? "no job code" }}
                      </div>
                    </td>
                    <td class="comp__amount">
                      <div class="name">
                        {{
                        new Intl.NumberFormat("en-DE", {
                        style: "currency",
                        currency: "dhs",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                        }).format(Math.round(data.Opportunity)) ?? 0
                        }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table__parent right_table w-60">
              <table class="w-100">
                <thead>
                  <tr>
                    <th>
                      <h5>Total Awarded</h5>
                    </th>
                    <th>
                      <h5>Conversion</h5>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, idx) in company" :key="idx">
                    <td v-if="idx === 0" class="comp__awards" rowspan="2">
                      <div class="name">
                        {{
                        new Intl.NumberFormat("en-DE", {
                        style: "currency",
                        currency: "dhs",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                        }).format(Math.round(company.total_awarded)) ?? 0
                        }}
                      </div>
                    </td>
                    <td v-if="idx === 0" class="comp__conversion" rowspan="2">
                      <div class="name">
                        {{ Math.round(company.conversion) }}%
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="sum_container">
          <div class="sum_content">
            <h3>Total proposals</h3>
            <h3>
              {{
              new Intl.NumberFormat("en-DE", {
              style: "currency",
              currency: "dhs",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
              }).format(Math.round(potentiality)) ?? 0
              }}
            </h3>
          </div>
        </div>
      </div>
      <div v-else>
        <h3 class="text-center pt-5 mb-5" style="color: #eca715">No Data</h3>
      </div>
    </div>
  </div>
</template>

<script>
import cluster from "../../api-services/cluster";
import axios from "axios";
import store from "../../store/store";

export default {
  name: "sales-manager-account-page",
  mounted() {
    const d = new Date();
    this.month = d.getMonth() + 1;
    this.year = d.getFullYear();
    this.salesManagerAccount = this.$store.state.auth.user.user.name;

    this.getAllClusters();
  },
  data() {
    return {
      salesManagerAccount: null,
      clusters: [],
      isDisabledOne: true,
      isDataComing: true,
      market: null,
      email: null,
      month: null,
      year: null,
      goal: null,
      companies: [],
      achievement: null,
      dataCoues: {},
      potentiality: 0,
      awarded: 0,
      po: 0,
      totalAwarded: 0,
      selected: "",
    };
  },
  methods: {
    async getAllClusters() {
      try {
        const response = await cluster.allClusters();
        this.clusters = response.data.data;
      } catch (e) {
        console.log(e);
      }
    },
    async getClusterSelected() {
      try {
        this.isDisabledOne = true;
        var result = this.clusters.filter((obj) => {
          return obj.id === this.selected;
        })[0];
        // console.log(result);
        this.isDataComing = false;
        //const response = await cluster.getClusterById(e.target.value);
        this.email = result.email;
        this.market = result.market_id;
        if (result.current_target.target !== null) {
          this.goal = result.current_target.target;
          this.isDisabledOne = false;
        } else {
          this.isDisabledOne = true;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getCouesData() {
      this.potentiality = 0;
      this.awarded = 0;
      this.po = 0;
      this.totalAwarded = 0;
      this.totalConversion = 0;
      this.totalOpportunityAwarded = 0;

      try {
        store.dispatch("setIsLoading", true);
        if (this.goal !== null) {
          this.isDataComing = true;
          await axios
            .post(process.env.VUE_APP_COUES_URL + "/salesViewAchievement", {
              email: this.email,
              month: this.month,
              year: this.year,
              market: this.market,
              goal: this.goal,
            })
            .then((responseCoues) => {
              store.dispatch("setIsLoading", true);
              if (responseCoues.data) {
                // store.dispatch('setIsLoading', false);
                if (responseCoues.data.po !== null) {
                  this.po = responseCoues.data.po;
                  this.achievement = responseCoues.data.po / this.goal;
                } else {
                  this.po = 0;
                  this.achievement = 0;
                }
              } else {
                store.dispatch("setIsLoading", false);
                this.achievement = 0;
              }
            });

          await axios
            .post(process.env.VUE_APP_COUES_URL + "/salesView", {
              email: this.email,
              month: this.month,
              year: this.year,
              market: this.market,
              goal: this.goal,
            })
            .then((responseCouesData) => {
              store.dispatch("setIsLoading", true);
              if (responseCouesData.data) {
                this.dataCoues = responseCouesData.data;
                this.calculations(responseCouesData.data);
                store.dispatch("setIsLoading", false);
              } else {
                store.dispatch("setIsLoading", false);
                this.achievement = 0;
              }
            });
        }
      } catch (e) {
        console.log(e);
      }
    },
    formatNumber(num) {
      return Math.round(parseFloat(num));
    },
    calculations(data) {
      this.companies = data;
      this.totalOpportunityAwarded = 0;
      let totalTasks = 0;
      let totalAwardedTasks = 0;
      for (let property in data) {
        this.companies[property] = data[property];
        this.companies[property].total_awarded = 0;
        this.companies[property].awarded_count = 0;
        this.companies[property].total = 0;
        this.companies[property].conversion = 0;

        for (let i = 0; i < this.companies[property].length; i++) {
          if (this.companies[property][i].is_award === 1) {
            this.companies[property].awarded_count++;
            this.companies[property].total_awarded += parseFloat(
              this.companies[property][i].Opportunity
            );
            this.awarded += +data[property][i].Opportunity;
            this.totalOpportunityAwarded += parseFloat(
              this.companies[property][i].Opportunity
            ); // Add the Opportunity to the new variable if it is awarded
            totalAwardedTasks++;
          }
          this.companies[property].total += parseFloat(
            this.companies[property][i].Opportunity
          );
          this.potentiality += parseFloat(
            this.companies[property][i].Opportunity
          );
          totalTasks++;
        }
        this.companies[property].conversion =
          (this.companies[property].total_awarded /
            this.companies[property].total) *
          100;
      }
      this.totalConversion = (totalAwardedTasks / totalTasks) * 100 ?? 0 + "%";
    },
    moveToScreen(){
      // this.$router.push(`/commercial-screen/?token=${process.env.VUE_APP_TOKEN}`);
      const url = `/commercial-screen/?token=${process.env.VUE_APP_TOKEN}`
      window.open(url, '_blank');
    }
  },
};
</script>

<style scoped>
.sales-manager-account table {
  border-collapse: collapse;
  border-style: hidden !important;
  color: #3c3c3b;
}
tr {
  display: table !important;
  width: 100%;
}
.sales-manager-account table th {
  padding: 0.5em 1em;
}
.sales-manager-account table h5 {
  font-size: 17px !important;
  font-weight: 700 !important;
}
.sales-manager-account table tbody {
  /* border: 1px solid #13161c1a; */
  color: #737373;
}
.sales-manager-account table td {
  border: 1px solid #13161c1a;
  padding: 0.5em 0em;
}
.sales-manager-account table td div {
  padding: 0 1em;
}
.sales-manager-account table td > *:not(:last-child) {
  border-bottom: 1px solid #13161c1a;
}
.tables__container {
  border: 1px solid #dadada;
  padding: 1em;
  margin-top: 0.5em;
  border-radius: 10px;
}
.table__parent {
  height: 18vh;
}
.left_table {
  width: 60%;
}
.right_table {
  width: 40%;
}
.comp__proposals {
  width: 25%;
}
.comp__job-code {
  width: 20%;
}
.comp__job-code_val{
  font-weight: 600;
}
.comp__amount {
  width: 15%;
}
.comp__conversion,
.comp__awards {
  width: 20%;
  font-weight: 700;
  font-size: 25px;
  color: #f47d1f;
  text-align: center;
}
.right_table tbody tr td {
  height: 12vh;
}
.left_table table {
  display: block;
}
.left_table thead {
  display: block;
}
.left_table tbody {
  max-height: 15vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
}

.page-header {
  color: #3c3c3b;
  font-weight: 800;
  font-size: 20px;
}
.page-header .name {
  display: inline-block;
  color: #f47d1f;
  font-weight: 700;
}
.page-content {
  min-height: 100vh;
  border-radius: 5px;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
}
.controls-container {
  background-color: #f47d1f;
  padding: 1em;
  border-radius: 8px;
}
.controls-container .form-select {
  width: 400px;
}
.filter-btn {
  font-weight: 600;
  padding: 0.5rem 3rem;
  color: white !important;
  background-color: #3c3c3b !important;
  cursor: pointer;
  border-radius: 25px;
}
.screen-btn {
font-weight: 600;
  padding: 0.5rem 3rem;
  background-color: white !important;
  color: #3c3c3b !important;
  cursor: pointer;
  border-radius: 25px;
}
.form-select {
  background-color: white;
  position: relative;
  color: #3c3c3b;
  font-weight: 300;
  cursor: pointer;
  border-radius: 25px;
  border-color: #707070 !important;
  font-size: 16px;
  padding: 0.5em 1em;
}
.targets {
  display: flex;
  justify-content: space-around;
  border: 1px solid #dadada;
  padding: 0.78em;
  margin-top: 0.5em;
  border-radius: 10px;
}
.content {
  width: 20%;
  display: flex;
  flex-direction: column;
}
.content .title h5 {
  color: #f47d1f;
  font-weight: 700 !important;
  font-size: 20px !important;
}
.content .details {
  color: rgba(19, 22, 28, 0.8);
  margin-top: 0.5em;
}

.account-name {
  background-color: rgba(19, 22, 28, 0.8);
  padding: 0.5rem;
  border-radius: 10px;
}
.account .account-name {
  text-align: center;
  color: #fff;
}
.sum_container {
  width: 100%;
  background-color: rgba(19, 22, 28, 0.8);
  padding: 0.5rem;
  border-radius: 10px;
  margin-top: 1rem;
  background: #ebebeb;
  color: #f47d1f;
}
.sum_content {
  display: flex;
  justify-content: space-between;
  padding: 1.5em;
}
.account-name h3 {
  font-weight: 800;
  font-size: 22px;
}
.sum_content h3 {
  font-weight: 800;
  font-size: 24px;
}
.sum_content h3:first-child {
  color: #3c3c3b;
}
</style>
